
export default class API {
	/**
	 * Calls the detect server api.
	 * @param path - the api path, ex. `user/login`
	 * @param data - key/value object to send as post data
	 * @param silent - whether or not to log stuff, if it happens a lot
	 * @return the response json
	 */
	public static async post(path: string, data: any = {}, silent = false): Promise<any> {
		const self = this;
		if (!path) {
			console.log('ERROR: api call needs path');
			return null;
		}

		try {
			const response = await fetch(`/api/${path}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ data }),
			});
			
			const r = await response.json();
			if (!silent) console.log('%c[api]', 'color: #CCC;', path, r);
			// if (self.debug && !silent) self.lastApi = r;
			
			return r;
		} catch (error) {
			console.error('API request failed:', error);
			return null;
		}
	}

	/**
	 * Requests from detect whether or not we're logged into SourceAudio as a super admin
	 */
	public async auth(): Promise<any> {
		const self = this;
		const r = await API.post('sa/saAuth');

		if (r.content?.id) {
			// self.user = r.content;
			return r.content;
		} else {
			return null;
		}
	}
}