import { react } from "react";
import { BrowserRouter, Routes, Route } from "react-router";
import { createTheme, MantineProvider } from '@mantine/core';

// core styles are required for all packages
import '@mantine/core/styles.css';

// other css files are required only if
// you are using components from the corresponding package
// import '@mantine/dates/styles.css';
// import '@mantine/dropzone/styles.css';
// import '@mantine/code-highlight/styles.css';
// ...

const theme = createTheme({});

import { StationsPage } from "./pages/stations";

export function App() {
	return (
		<MantineProvider theme={theme}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<h1>HOME</h1>} />
					<Route path="test" element={<h1>TEST</h1>} />
					<Route path="stations" element={<StationsPage />} />
					<Route path="*" element={<h1>404</h1>} />
				</Routes>
			</BrowserRouter>
		</MantineProvider>
	);
}