import { useState, useEffect } from 'react';
import API from '../lib/api';

import { Grid, GridCol } from '@mantine/core';

export function StationsPage() {
	const [stations, setStations] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchStations = async () => {
			const r = await API.post('station/getStationData', { limit: 5 });
			if (r.error) {
				console.error(r.error);
				return;
			}
			setStations(r);
			setLoading(false);
		}
		fetchStations();
	}, []);

	if (loading) {
		return <h1>Loading...</h1>;
	}

	return (
		<>
			<h1>Stations</h1>
			<Grid p="lg">
				{stations.map((station, index) => (
					<GridCol key={index} span={12} sm={6} md={4}>
						<div>
							<h2>{station.name}</h2>
						</div>
					</GridCol>
				))}
			</Grid>
		</>
	);
}